<template>
    <asc-preview-list
        :items="items"
    >
        <template v-slot:default>
            <content-placeholders>
                <content-placeholders-heading :img="true" />
            </content-placeholders>
        </template>
    </asc-preview-list>
</template>

<script>
import AscPreviewList from '~/patterns/organisms/preview-list/preview-list.vue';

export default {
    components: {
        AscPreviewList
    },

    props: {
        count: {
            type: Number,
            default: 8
        }
    },

    computed: {
        items() {
            return new Array(this.count);
        }
    }
};
</script>
