<template>
    <div class="results">
        <slot
            :users="users"
            :paginator-info="paginatorInfo"
            :is-pending="$fetchState.pending"
            :current-page-number="currentPageNum"
        />
    </div>
</template>

<script>
import Paginatable from '~/mixins/paginatable';
import userIndexQuery from '~/graphql/queries/user/index.graphql';

export default {
    mixins: [
        Paginatable
    ],

    props: {
        showActiveFirst: {
            type: Boolean,
            default: false
        },

        itemsPerPage: {
            type: Number,
            default: 24
        },

        byTopic: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            users: [],
            paginatorInfo: null
        };
    },

    async fetch() {
        const variables = {
            page: this.currentPageNum,
            amount: this.itemsPerPage
        };

        if (this.showActiveFirst) {
            variables.showActiveFirst = true;
        } else {
            variables.orderBy = [{ column: 'CREATED_AT', order: 'DESC' }];
        }

        if (this.byTopic && this.$auth.user?.followingTopics?.length) {
            variables.isFollowingTopics = {
                operator: 'IN',
                column: 'ID',
                value: this.$auth.user.followingTopics.map(({ id }) => id)
            };
        }

        const results = await this.$gql.executeQuery(userIndexQuery, variables);

        if (results && results.data) {
            this.users = results.data.users.data;
            this.paginatorInfo = results.data.users.paginatorInfo;
        }
    }
};
</script>
