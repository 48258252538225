<template>
    <nav class="update-filters sticky">
        <div
            v-for="type in types"
            :key="type.value"
            class="update-filters__filter"
            :data-active="activeType === type.value"
        >
            <asc-update-type-link
                :to="generateLink(type.value)"
                :update-type="type"
                class="update-filters__link"
                :data-active="$route.path === generateLink(type.value)"
            />

            <nuxt-link v-if="activeType === type.value" :to="filterLinkPrefix" class="update-filters__remove">
                <asc-icon icon="cross" />
            </nuxt-link>
        </div>

        <asc-form-field>
            <asc-select
                class="update-filters__select"
                :options="typesForSelect"
                :value="activeType"
                placeholder="Filter on type"
                @input="handleSelectInput"
            />
        </asc-form-field>

        <asc-form-field>
            <asc-select
                v-if="activeType === 'event'"
                :options="periodOptions"
                placeholder="Period"
                :value="$route.query.eventPeriod"
                @input="handlePeriodInput"
            />
        </asc-form-field>

        <asc-form-field>
            <asc-select
                v-if="activeType === 'event'"
                :options="eventTypesForSelect"
                placeholder="Type of event"
                :value="$route.query.eventType"
                @input="handleEventTypeInput"
            />
        </asc-form-field>
    </nav>
</template>

<script>
import { formatISO, eachMonthOfInterval, addMonths, format } from 'date-fns';
import { mapGetters } from 'vuex';

import AscIcon from '~/patterns/atoms/icon/icon.vue';
import AscSelect from '~/patterns/atoms/select/select.vue';
import AscFormField from '~/patterns/molecules/form-field/form-field.vue';
import AscUpdateTypeLink from '~/patterns/atoms/update-type-link/update-type-link.vue';

export default {
    components: {
        AscFormField,
        AscSelect,
        AscIcon,
        AscUpdateTypeLink
    },

    props: {
        filterLinkPrefix: {
            type: String,
            required: false,
            default: '/updates'
        }
    },

    computed: {
        ...mapGetters({
            types: 'update/types',
            eventTypes: 'update/eventTypes'
        }),

        activeType() {
            if (this.$route.params.type) {
                return this.$route.params.type;
            }
            return null;
        },

        typesForSelect() {
            const types = [...this.types];
            types.unshift({ value: '', label: 'All updates' });
            return types;
        },

        eventTypesForSelect() {
            const eventTypes = [...this.eventTypes];
            eventTypes.unshift({ value: '', label: 'All types' });
            return eventTypes;
        },

        periodOptions() {
            const now = new Date(),
                firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1),
                firstDayOfLastMonth = addMonths(firstDayThisMonth, 5),
                range = eachMonthOfInterval({
                    start: firstDayThisMonth,
                    end: firstDayOfLastMonth
                }),
                options = range.map((date) => {
                    return {
                        label: format(date, 'LLLL yyyy'),
                        value: formatISO(date)
                    };
                });

            options.unshift({ label: 'All events from now', value: '' });

            return options;
        }

    },

    methods: {
        /** Allows setting a custom prefix for the filter links */
        generateLink(value) {
            return this.filterLinkPrefix + '/' + value;
        },

        handleSelectInput(value) {
            if (value && value.length) {
                this.$router.push(this.generateLink(value));
            } else {
                this.$router.push(this.filterLinkPrefix);
            }
        },

        handlePeriodInput(value) {
            if (value && value.length) {
                this.$router.push({ query: { ...this.$route.query, eventPeriod: value } });
            } else {
                const queryClone = { ...this.$route.query };
                delete queryClone.eventPeriod;
                this.$router.push({ query: queryClone });
            }
        },

        handleEventTypeInput(value) {
            if (value && value.length) {
                this.$router.push({ query: { ...this.$route.query, eventType: value } });
            } else {
                const queryClone = { ...this.$route.query };
                delete queryClone.eventType;
                this.$router.push({ query: queryClone });
            }
        }
    }
};
</script>

<style lang="less" src="./update-filters.less" />
