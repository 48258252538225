<template>
    <ul
        v-if="items && items.length"
        class="preview-list"
    >
        <li
            v-for="(item, index) in items"
            :key="index"
        >
            <slot :item="item" />
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: null,
            required: true
        }
    }
};
</script>

<style lang="less" src="./preview-list.less" scoped></style>
