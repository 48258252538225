<template>
    <div
        v-if="logos && logos.length"
        class="logo-grid"
    >
        <nuxt-link
            v-for="(logo, index) in logoArray"
            :key="index"
            :to="`/organisations/${logo.slug}`"
        >
            <img
                :src="logo.src"
                :title="logo.title"
                :alt="logo.title"
                loading="lazy"
            >
        </nuxt-link>
    </div>
</template>

<script>
import { shuffle } from 'lodash';

export default {
    props: {
        logos: {
            type: Array,
            default() {
                return [];
            }
        },

        randomized: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            logoArray: null
        };
    },

    mounted() {
        if (this.randomized) {
            this.logoArray = shuffle(this.logos);
        } else {
            this.logoArray = this.logos;
        }
    }
};
</script>

<style lang="less" src="./logo-grid.less" scoped></style>
