<template>
    <asc-preview>
        <template v-slot:body>
            <asc-preview-link
                :href="`/organisations/${organisation.slug}`"
                :image="{
                    srcSmall: organisation.logoSmall,
                    src: organisation.logo
                }"
            >
                <template v-slot:title>{{ organisation.title }}</template>

                <template v-if="organisation.subtitle" v-slot:subtitle>
                    {{ organisation.subtitle }}
                </template>
            </asc-preview-link>
        </template>

        <template v-slot:actions>
            <span class="hidden-xxs">
                <template v-if="organisation.members_count > 1">
                    {{ organisation.members_count }} members
                </template>

                <template v-else-if="organisation.members_count === 1">
                    1 member
                </template>

                <template v-else>
                    No members
                </template>
            </span>

            <asc-button
                element="nuxt-link"
                :to="`organisations/${organisation.slug}`"
                :title="`View ${organisation.title}`"
            >
                View
            </asc-button>
        </template>
    </asc-preview>
</template>

<script>
import AscPreview from '../preview.vue';

import AscPreviewLink from '~/patterns/atoms/preview-link/preview-link.vue';
import AscButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        AscPreview,
        AscPreviewLink,
        AscButton
    },

    props: {
        organisation: {
            required: true,
            type: Object,
            default: null
        }
    }
};
</script>
