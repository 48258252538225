import { render, staticRenderFns } from "./hero.vue?vue&type=template&id=2d8ed88a"
import script from "./hero.vue?vue&type=script&lang=js"
export * from "./hero.vue?vue&type=script&lang=js"
import style0 from "./hero.less?vue&type=style&index=0&prod&lang=less&external"
import style1 from "./hero.vue?vue&type=style&index=1&id=2d8ed88a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports