<template>
    <article class="preview-link">
        <component
            :is="href ? 'nuxt-link' : 'div'"
            :to="href ? href : null"
            class="preview-link__wrapper"
        >
            <div class="preview-link__image">
                <slot name="image">
                    <img
                        v-if="image && image.src"
                        :src="image.src"
                        :srcset="`${image.srcSmall} 48w, ${image.src} 96w`"
                        :height="48"
                        :width="48"
                        loading="lazy"
                    >
                </slot>
            </div>

            <div class="preview-link__body">
                <h3 class="preview-link__title">
                    <slot name="title" />
                </h3>

                <h4 v-if="!!$slots.subtitle" class="preview-link__subtitle">
                    <slot name="subtitle" />
                </h4>
            </div>
        </component>
    </article>
</template>

<script>
export default {
    props: {
        href: {
            type: String,
            default: null
        },

        image: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="less" src="./preview-link.less" />
