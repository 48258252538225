<template>
    <asc-logo-grid v-if="logos.length" :logos="logos" />
    <p v-else>Sorry! We don't have any partners to show here right now.</p>
</template>

<script>
import AscLogoGrid from '~/patterns/molecules/logo-grid/logo-grid.vue';

export default {
    components: {
        AscLogoGrid
    },

    props: {
        items: {
            type: Array,
            default: () => [],
            required: true
        }
    },

    computed: {
        logos() {
            return this.items.map((organisation) => {
                return {
                    src: organisation.logoLarge,
                    title: organisation.title,
                    slug: organisation.slug
                };
            });
        }
    }
};
</script>
