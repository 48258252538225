<template>
    <transition name="fade" mode="out-in">
        <section v-if="pending" key="pending" class="results">
            <a id="top-of-results" name="top-of-results" />

            <slot name="placeholder">
                <template v-for="index in new Array(8)">
                    <slot name="placeholder-item" :index="index" />
                </template>
            </slot>
        </section>

        <section v-else-if="results && results.length" key="results" class="results">
            <a id="top-of-results" name="top-of-results" />

            <div>
                <slot :results="results" />
            </div>

            <div
                v-if="showPagination && paginatorInfo && paginatorInfo.lastPage > 1"
                class="results__paginator"
            >
                <asc-paginator
                    :total-pages="paginatorInfo.lastPage"
                    :current-page="paginatorInfo.currentPage"
                />
            </div>
        </section>

        <section v-else key="no-results" class="results">
            <slot name="no-results">
                <p>Sorry! There's no results to show here right now.</p>
            </slot>
        </section>
    </transition>
</template>

<script>
import AscPaginator from '~/patterns/molecules/paginator/paginator.vue';

export default {
    components: {
        AscPaginator
    },

    props: {
        results: {
            type: Array,
            default: null
        },

        paginatorInfo: {
            type: Object,
            default: null
        },

        pending: {
            type: Boolean,
            default: false
        },

        showPagination: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style lang="less" src="./results.less"></style>
