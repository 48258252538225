<template>
    <ul v-if="channels.length" class="channels-shuffle">
        <li v-for="channel in channels" :key="channel.id">
            <asc-button
                :tint="channel.color"
                :to="`/channel/${channel.slug}`"
                element="nuxt-link"
                class="dn-button--channel"
            >
                {{ channel.title }}
            </asc-button>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import AscButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        AscButton
    },

    computed: {
        ...mapGetters({
            channels: 'topics/channels'
        })
    }
};
</script>

<style lang="less" src="./channels-shuffle.less" />
