<template>
    <div class="template">
        <div class="template__top">
            <slot name="top" />
        </div>

        <main class="template__body">
            <slot />
        </main>

        <div class="template__bottom">
            <slot name="bottom" />
        </div>
    </div>
</template>

<script>

export default {

};
</script>
