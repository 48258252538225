<template>
    <asc-results v-bind="$attrs">
        <template v-slot:placeholder>
            <asc-preview-list-placeholder :count="25" />
        </template>

        <template v-slot:default="{ results }">
            <asc-preview-list :items="results">
                <template v-slot:default="{ item }">
                    <organisation-preview v-if="item.__typename === 'Organisation'" :organisation="item" />
                    <user-preview v-else-if="item.__typename === 'User'" :user="item" />
                </template>
            </asc-preview-list>
        </template>
    </asc-results>
</template>

<script>
import OrganisationPreview from '~/patterns/molecules/preview/presets/organisation-preview';
import UserPreview from '~/patterns/molecules/preview/presets/user-preview';

import AscResults from '~/patterns/organisms/results/results.vue';
import AscPreviewList from '~/patterns/organisms/preview-list/preview-list.vue';
import AscPreviewListPlaceholder from '~/patterns/organisms/preview-list/preview-list-placeholder.vue';

export default {
    components: {
        AscResults,
        AscPreviewList,
        AscPreviewListPlaceholder,
        UserPreview,
        OrganisationPreview
    },

    props: {
        perPage: {
            type: Number,
            default: 8,
            required: false
        }
    }
};
</script>
