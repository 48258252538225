<template>
    <div class="hero">
        <h1 class="hero__title">
            <span>
                <slot name="title">{{ title }}</slot>
            </span>
        </h1>

        <div v-if="image" class="hero__image">
            <div class="hero__image__wrapper">
                <lazy-img
                    :src="image.large.src"
                    :src-tiny="image.tiny.src"
                    :srcset="`
                        ${image.xxlarge.src} ${image.xxlarge.width}w,
                        ${image.xlarge.src} ${image.xlarge.width}w,
                        ${image.large.src} ${image.large.width}w,
                        ${image.small.src} ${image.small.width}w`
                    "
                    class="lazy-img--cover"
                    :width="1920"
                    :height="565"
                />
            </div>
        </div>

        <div class="hero__body">
            <content-layout>
                <template v-slot:sidebar>
                    <div class="hero__sidebar">
                        <slot name="sidebar" />
                    </div>
                </template>

                <template v-slot:default>
                    <div class="hero__content">
                        <slot name="default" />
                    </div>
                </template>
            </content-layout>
        </div>

        <transition name="herobg">
            <div
                :key="backgroundStyle"
                :data-background="backgroundStyle"
                class="hero__background"
            />
        </transition>
    </div>
</template>

<script>
import ContentLayout from '~/patterns/organisms/content-layout/content-layout.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';
import { getGradientBackgrounds } from '~/helpers/colors';

const backgroundStyles = [
    'gradient-top',
    'rainbow',
    ...getGradientBackgrounds()
];

export default {
    components: {
        ContentLayout,
        LazyImg
    },

    props: {
        title: {
            type: String,
            default: ''
        },

        image: {
            type: Object,
            default: null
        },

        backgroundStyle: {
            type: String,
            default: backgroundStyles[0],
            validator(value) {
                return backgroundStyles.includes(value);
            }
        }
    }
};
</script>

<style src="./hero.less" lang="less"></style>

<style lang="less">
.hero {
    .content-layout__sidebar,
    .content-layout__body {
        padding: 0;
    }
}
</style>
