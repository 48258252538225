<template>
    <asc-preview>
        <template v-slot:body>
            <asc-preview-link
                :href="`/members/${user.id}`"
            >
                <template v-slot:image>
                    <asc-avatar :user="user" :link-to-user="false" />
                </template>

                <template v-slot:title>{{ user.name }}</template>

                <template v-if="user.profession" v-slot:subtitle>
                    {{ user.profession }}
                    <template v-if="user.organisations && user.organisations.length"> at {{ user.organisations[0].title }}</template>
                </template>
            </asc-preview-link>
        </template>

        <template v-slot:actions>
            <span v-if="user.likes_count === 1" class="hidden-xxs">1 thumbs up</span>
            <span v-else-if="user.likes_count > 1" class="hidden-xxs">{{ user.likes_count }} thumbs up</span>

            <!--
            @TODO: Uncomment this button when it's possible to follow users. Styling is mostly done.
            <asc-button
                :to="`/members/${user.id}`"
                class="dn-button--primary"
            >
                <span class="hidden-xxs hidden-xs">Follow</span>

                <template v-slot:after>
                    <asc-icon icon="plus" />
                </template>
            </asc-button>
            -->
        </template>
    </asc-preview>
</template>

<script>
import AscPreview from '../preview.vue';

import AscAvatar from '~/patterns/atoms/avatar/avatar.vue';
import AscPreviewLink from '~/patterns/atoms/preview-link/preview-link.vue';

export default {
    components: {
        AscAvatar,
        AscPreview,
        AscPreviewLink
    },

    props: {
        user: {
            required: true,
            type: Object,
            default: null
        }
    }
};
</script>
