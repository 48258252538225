export default {
    props: {
        itemsPerPage: {
            type: Number,
            default: 8
        },

        showPagination: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            currentPageNum: 1
        };
    },

    watch: {
        '$route.query.page': {
            immediate: true,
            handler(newPageNumber) {
                if (newPageNumber) {
                    this.currentPageNum = Number(newPageNumber);
                }
            }
        },

        currentPageNum() {
            if (typeof document !== 'undefined') {
                document.getElementById('top-of-results').scrollIntoView({ behavior: 'smooth' });
            }

            this.$fetch();
        }
    }
};
